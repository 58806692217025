$(document).on('submit', "form.subscribe", function(e){

  var f = $(this).addClass('sending');
  
  grecaptcha.ready(function() {
    grecaptcha.execute('6LfYNMYcAAAAAGuxJ42UFu1I1sCBgrdw0BwG1pXP', { action: 'submit' }).then(function(token) {
      
      f.prepend('<input type="hidden" name="token" value="' + token + '">');
      
      $.ajax({
        method: 'POST',
        url: f.attr('action'),
        data: f.serialize(),
        dataType: 'script'
      }).done(function(){});
      
    });
  });
    
  
  
  e.preventDefault();  
});


$(document).on('submit', "form.booking-form", function(e){

  var f = $(this).addClass('sending');

  grecaptcha.ready(function() {
    grecaptcha.execute('6LfYNMYcAAAAAGuxJ42UFu1I1sCBgrdw0BwG1pXP', { action: 'submit' }).then(function(token) {
      
      f.prepend('<input type="hidden" name="token" value="' + token + '">');
      
      f.get(0).submit();
      
    });
  });
    
  
  
  e.preventDefault();  
});

// Subscribe - open in overlay
$(document).on('click', "a[href='/subscribe/journal']", function(e){
  
  $this = $(this);
  
  if ($this.data('loading') != true) {    
  
    $this.data('loading', true);
    
    $.ajax({
      type: 'GET',
      url: $this.attr('href'),
      dataType: 'script'      
    }).done(function(){    
      
      var c = $this.parents('section:first');

      $('#overlay').each(function(){
        var overlay = $(this);
        overlay.css({
          'margin-top': Math.max(c.offset().top - ((overlay.height()-c.height())/2),0) + 'px',
          'background-color': $this.parents('section:first').css('background-color')
        });
      });  
      
      $this.data('loading', false);
    });
    
  } 

  e.preventDefault();
});

