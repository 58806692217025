import $ from 'jquery';  
window.jQuery = $; window.$ = $

import Cookies from 'js-cookie';
import 'javascripts/modernizr';
import 'javascripts/subscribers';


// On first load
$(function() {
  
  var t = setTimeout(function(){    
    $('meta[name=msg]').each(function(){
      var $this = $(this);  
      $.ajax({
        type: "GET",
        url: $this.attr('href'),
        dataType: 'script'
      }).done(function(){    
        $(document).on('click', '.popup-msg button', function(e){
          var $this = $(this);          
          Cookies.set($this.data('name'), $this.data('value'), { expires: 30 });
                    
          if (($this.data('value') == '1')) {
            gtag('consent', 'update', {'analytics_storage': 'granted'});
            $(".video-container").trigger('load:video');
          }
          
          $(".popup-msg").remove();          
          e.preventDefault();
        });        
      });          
    })    
  }, 600);   
  
  
  if (Cookies.get('approve_cookie') == '1') {
    $(".video-container").trigger('load:video')
  }

});


//// On all loads
//document.addEventListener("turbolinks:load", function(event) {
//    
//  // Analytics
//  if (typeof(ga) == 'function') {
//    ga('set', 'location', event.data.url);
//    ga('send', 'pageview');
//  }
//  
//});


// Video
$(document).on('click', ".video-container button.btn", function(e){
  $(this).parents('.video-container:first').trigger('load:video');  
  e.preventDefault();
});

$(document).on('load:video', ".video-container", function(e){
  var $this = $(this);  
  $this.html($this.data('html'));
})

// Header
$(document).on('click', "header a.menu", function(e){
  $('header').toggleClass('open');
  e.preventDefault();
});


// Footer 
$(document).on('click', "footer .sections h2", function(e){
  $(this).parent().toggleClass('open').siblings('.open').removeClass('open');
});

